import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: "root",
})
export class LoadService {
  closeBottomDrawer = new BehaviorSubject<boolean>(false);
   subscriptions$ = new SubSink();
  constructor(
      ) {}
}