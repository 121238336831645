import {
  Component,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { PeriodTripsList } from "../../../Swagger-Gen";
import { Store, select } from "@ngrx/store";
import * as fromPreviousTrips from "./state/previous-trips-loads.reducer";
import { takeWhile } from "rxjs/operators";
import { LoadingUtility } from "../../../shared/utilities/LoadingUtility";
import { Router } from "@angular/router";
import * as fromPaperWorkReducer from "./../../paperwork/state/paperwork.reducer";
import * as paperWorkActions from "./../../paperwork/state/paperwork.actions";
import { environment } from "src/environments/environment";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { JobTrip } from "src/app/Swagger-Gen-V2/model/jobTrip";
import nativePlugin from "src/plugins/nativePlugin";
import { AuthService } from "src/app/auth/auth.service";
import { SubSink } from "subsink";
import { TelemetryUtility } from "src/app/shared/utilities/telemetry-utilities";
import { TelemetryConstants } from "src/app/shared/utilities/telemetry-constants";
import { ScannedPaperwork } from "src/app/Swagger-Gen-V2/model/ScannedPaperwork";
import { Preferences } from "@capacitor/preferences";
import { BottomDrawerComponent } from "src/app/shared/components/bottom-drawer/bottom-drawer.component";

@Component({
  selector: "app-previous-trips-loads",
  templateUrl: "./previous-trips-loads.component.html",
  styleUrls: ["./previous-trips-loads.component.scss"],
})
export class PreviousTripsLoadsComponent implements OnInit, OnDestroy {
  @Input() trips;
  @Output("getPreviousJobs") getPreviousJobs: EventEmitter<any> =
    new EventEmitter();
  @Input() isPreviousJobLoading: boolean;
  @Input() closeBottomDrawer: number = 0;
  @ViewChild(BottomDrawerComponent) bottomDrawer: BottomDrawerComponent;
  componentActive = true;
  showBottomDrawer = false;
  driverId = "";
  showSpinner = false;
  isEdgeConnectDevice: boolean = false;
  recipient = environment.defaultRecipient;
  tripsByTimePeriods: any[] = []; // collection storing data on trips that occurred during a given time period
  private subscriptions$ = new SubSink();
  actionSheetRows = [
    {
      title: "Generate Job Envelope and Scan",
      handler: async () => {
        this.bottomDrawer.closeDrawer();
        await Preferences.set({
          key: "job-page-url",
          value: JSON.stringify({ url: "/loads", tab: "Previous" }),
        });
        this.router.navigate(["/loads/paperwork"]);
      },
    },
    {
      title: "Scan Paperwork Only",
      handler: async () => {
        this.bottomDrawer.closeDrawer();
        await Preferences.set({
          key: "job-page-url",
          value: JSON.stringify({ url: "/loads", tab: "Previous" }),
        });
        this.scanPaperWork();
      },
    },
    {
      title: "View Existing Paperwork",
      handler: async () => {
        this.bottomDrawer.closeDrawer();
        await Preferences.set({
          key: "job-page-url",
          value: JSON.stringify({ url: "/loads", tab: "Previous" }),
        });
        this.viewExistingPaperWork();
      },
    },
  ];
  // Instantiates component
  constructor(
    private store: Store<fromPreviousTrips.State>,
    private loadingUtility: LoadingUtility,
    private paperWorkStore: Store<fromPaperWorkReducer.State>,
    private router: Router,
    private analytics: AnalyticsService,
    private telemetryUtility: TelemetryUtility,
    private readonly authService: AuthService  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["trips"]?.currentValue !== null) {
      this.showDriverPreviousTrips(this.trips);
    }
    if (changes["closeBottomDrawer"]?.currentValue) {
      this.showBottomDrawer = false;
    }
  }

  // Method called when the component is initialized
  async ngOnInit() {
    console.log("PreviousTripsLoadsComponent ngOnInit");

    this.isEdgeConnectDevice = environment.isEdgeConnectDevice;

    this.analytics.trackEvent(
      "User",
      "Clicked Previous Tab On Jobs Screen",
      "Clicked-Previous-Tab-On-Jobs-Screen"
    );

    this.telemetryUtility.recordTelemetry(
      TelemetryConstants.CLICK_PREVIOUS_TAB_JOB_SCREEN
    );

    this.driverId = await this.authService.getUserIdentitityKey();

    this.subscriptions$.sink = this.store
      .pipe(
        select(fromPreviousTrips.isPreviousJobsLoading),
        takeWhile(() => this.componentActive)
      )
      .subscribe((isPreviousJobsLoading: boolean) => {
        if (!isPreviousJobsLoading) {
          this.loadingUtility.hideLoader();
        }
      });

    if (this.isPreviousJobLoading === false) {
      this.getPreviousJobs.emit();
    }
  }


  // Handler called when the component is discarded from memory
  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions$.unsubscribe();
  }
  navigateToLanding(){
    this.router.navigate(["/deep-link-landing"]);
  }
  showPaperworkDrawer(trip: JobTrip) {
    this.recipient =
      trip && trip.clientContactName && trip.clientContactName === "STREAMLINE"
        ? environment.streamLineRecipient
        : environment.defaultRecipient;
    this.showBottomDrawer = !this.showBottomDrawer;
    if (this.showBottomDrawer) {
      this.paperWorkStore.dispatch(new paperWorkActions.SelectedJob(trip));
    }
  }

  async scanPaperWork() {
    let data = await nativePlugin.scanPaperWork({
      recipient: this.recipient,
      identityId: this.driverId,
      appName: environment.appName,
      firstName: "",
      lastName: "",
      docuPath: "",
      isEdgeConnectDevice: this.isEdgeConnectDevice,
      isRequestFromDeepLink: AuthService.isRequestFromDeeplink,
    });

    if (data && data.confirmationNumber) {
      let confirmationCodes: ScannedPaperwork = {
        scannedBy: this.driverId,
        scanDateTime: new Date(Date.now()),
        numberOfPages: data.numberOfImages,
        originCityName: "",
        originStateName: "",
        destinationCityName: "",
        destinationStateCode: "",
        tripId: "",
        label: {
          labelName: "",
          code: data.confirmationNumber,
        },
      };
      AuthService.isScanPaperworkSuccess = true;
      this.paperWorkStore.dispatch(
        new paperWorkActions.ConfirmationCodesToBePosted(confirmationCodes)
      );
      this.router.navigate(["/loads/paperwork/confirmation"]);
    } else {
      AuthService.isScanPaperworkSuccess = false;
    }
  }
  viewExistingPaperWork() {
    this.router.navigate(["/loads/paperwork/confirmation_list"]);
  }

  // Renders previous trips that were retrieved using a call made to the back end API
  showDriverPreviousTrips(driverTripsList: Array<any>) {
    if (driverTripsList != null && driverTripsList.length > 0) {
      let currentTrip = null;
      let allTrips: PeriodTripsList = null;

      allTrips = {
        title: "",
        trips: [],
      };

      this.tripsByTimePeriods = [];

      // Format data for trip list component
      //
      for (let i = 0; i < driverTripsList.length; i++) {
        currentTrip = {
          id: driverTripsList[i].id,

          departure:
            driverTripsList[i].departureLocation.city +
            ", " +
            driverTripsList[i].departureLocation.state,

          destination:
            driverTripsList[i].arrivalLocation.city +
            ", " +
            driverTripsList[i].arrivalLocation.state,

          loadNumber: driverTripsList[i].loadNumber,
          details: driverTripsList[i],
        };

        allTrips.trips.push(currentTrip);
      }

      this.tripsByTimePeriods.push(allTrips);
    }
  }
}
