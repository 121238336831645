import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingUtility } from "../../shared/utilities/LoadingUtility";
import { AuthService } from "src/app/auth/auth.service";
import * as fromPaperWorkReducer from "./../paperwork/state/paperwork.reducer";
import { select, Store } from '@ngrx/store';
import * as paperWorkActions from "./../paperwork/state/paperwork.actions";
import nativePlugin from 'src/plugins/nativePlugin';
import { ScannedPaperwork } from 'src/app/Swagger-Gen-V2/model/ScannedPaperwork';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import * as fromPreviousTrips from 'src/app/modules/loads/previous-trips-loads/state/previous-trips-loads.reducer';
import * as fromCurrentTrip from "./../../modules/loads/trip-load-infos/state/trip-load-infos.reducer";
import deeplinkPlugin from 'src/plugins/deeplinkPlugin';




@Component({
  selector: 'app-app-landing-page-deeplink',
  templateUrl: './app-landing-page-deeplink.component.html',
  styleUrls: ['./app-landing-page-deeplink.component.scss'],
})
export class AppLandingPageDeeplinkComponent  implements OnInit, OnDestroy {

  activeDriverId = "";
  isEdgeConnectDevice: boolean = false;
  recipient = environment.defaultRecipient; 
   previousTrips;
   currentTrip;
   subscriptions$ = new SubSink();
  
  constructor( private readonly activatedRoute: ActivatedRoute,
    private readonly loadingUtility: LoadingUtility,
     private readonly authService: AuthService,
     private readonly router: Router,
     private readonly paperWorkStore: Store<fromPaperWorkReducer.State>,
     private readonly store: Store<fromPreviousTrips.State>,
     private readonly  currentTripStore: Store<fromCurrentTrip.State>
  ) { }

  async ngOnInit() {
    this.activeDriverId = await this.authService.getUserIdentitityKey();
    this.isEdgeConnectDevice = true;
    this.processDeeplink();
  }

   async processDeeplink() {
    this.loadingUtility.hideLoader()
      //Deep link process
      const requestFrom =
        this.activatedRoute.snapshot.queryParamMap.get("requestFrom");
      const page = this.activatedRoute.snapshot.queryParamMap.get("page");
      if (requestFrom == "deeplink" && page == "Previous") {

        const tripId = this.activatedRoute.snapshot.queryParamMap.get("tripId");
        this.previousTrips = await new Promise((resolve) => {
                  this.subscriptions$.sink = this.store.pipe(select(fromPreviousTrips.getDriverPreviousTrips)).subscribe(
                    data => {
                      if (data) {
                        resolve(data);
                      }
                    }
                  );
                });  
        let previousTrip = this.previousTrips.find((trip) => trip.id === tripId);
        if (previousTrip != null) {
          this.paperWorkStore.dispatch(
            new paperWorkActions.SelectedJob(previousTrip)
          );
        }
        
       this.scanPaperWork();
      }
       if (requestFrom == "deeplink" && page == "Current") {
           
       this.currentTrip = await new Promise((resolve) => {
              this.subscriptions$.sink = this.currentTripStore.pipe(select(fromCurrentTrip.getCurrentTrip)).subscribe(
                data => {
                  if (data) {
                    resolve(data);
                  }
                }
              );
            });  
            this.paperWorkStore.dispatch(
              new paperWorkActions.SelectedJob(this.currentTrip)
            );
            this.scanPaperWork();
          }
    }

    async scanPaperWork() {
      let scanData = await nativePlugin.scanPaperWork({
        recipient: this.recipient,
        identityId: this.activeDriverId,
        appName: environment.appName,
        firstName: "",
        lastName: "",
        docuPath: "",
        isEdgeConnectDevice: this.isEdgeConnectDevice,
        isRequestFromDeepLink: AuthService.isRequestFromDeeplink,
      });
  
      if (scanData?.confirmationNumber) {
        
        let confirmationCodes: ScannedPaperwork = {
          scannedBy: this.activeDriverId,
          scanDateTime: new Date(Date.now()),
          numberOfPages: scanData.numberOfImages,
          originCityName: "",
          destinationStateCode: "",
          originStateName:"",
          destinationCityName:"",
          tripId: "",
          label: {
            labelName: "",
            code: scanData.confirmationNumber,
          },
        };
        AuthService.isScanPaperworkSuccess = true;
        this.paperWorkStore.dispatch(
          new paperWorkActions.ConfirmationCodesToBePosted(confirmationCodes)
        );
        this.router.navigate(["/loads/paperwork/confirmation"]);
      } else if(scanData?.message){
        console.log("submit paperwork unsuccessfull" )
        AuthService.isScanPaperworkSuccess = false;
          deeplinkPlugin.DeepLinkFailureResponse({});
      }
    }

    ngOnDestroy() {
      this.subscriptions$.unsubscribe();
    }
}
