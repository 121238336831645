import { Inject, Injectable } from "@angular/core";
import {select, Store} from "@ngrx/store";
import {IUserInfo} from "./user-info.model";
import {DispatchService} from "../shared/services/dispatchers/dispatch.service";
import {StorageConstants} from "../shared/utilities/storage-constants";
import { StorageKeyValue } from "../shared/models/storage-key-value";
import { OktaAuth } from "@okta/okta-auth-js";
import { Platform, AlertController,LoadingController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import OktaSignIn from '@okta/okta-signin-widget';
import { SubSink } from 'subsink';
import { Preferences } from '@capacitor/preferences';
import { AdministrationFeatureFlags } from "../Swagger-Gen-V2/model/administrationFeatureFlags";
import { OKTA_AUTH } from "@okta/okta-angular";
import { DeepLinkRoutes } from "src/app/deep-links.routing";
import { Router } from "@angular/router";
import * as fromPreviousTrips from 'src/app/modules/loads/previous-trips-loads/state/previous-trips-loads.reducer';
import * as previousTripsActions from "src/app/modules/loads/previous-trips-loads/state/previous-trips-loads.actions";
import { LoadingUtility } from "../shared/utilities/LoadingUtility";
import * as fromCurrentTrip from "./../modules/loads/trip-load-infos/state/trip-load-infos.reducer";
import deeplinkPlugin from 'src/plugins/deeplinkPlugin';
import {  RequestService } from 'src/app/Swagger-Gen-V2';


declare let pendo;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  userInfo: IUserInfo;
  widget;
  isPendoEnabled: boolean;
  subscriptions$ = new SubSink();
  featureFlags: AdministrationFeatureFlags;
  config;
  static isRequestFromDeeplink: boolean = false;
  static isScanPaperworkSuccess: boolean = false;
  tripId: any;
  timeout :any;
  urlQueryParams :any;

  constructor(
    private dispatchService: DispatchService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private platform: Platform,
    public router: Router,
    private alertController: AlertController,
    private readonly loadingController: LoadingController,
    private readonly loadingUtility: LoadingUtility,
    private readonly store: Store<fromPreviousTrips.State>,
    private readonly currentTripStore: Store<fromCurrentTrip.State>,
    private readonly requestService: RequestService
     
  ) {   
    this.config = new OktaAuth({
      issuer: environment.oidc.issuer,
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
    });

    this.widget = new OktaSignIn({
      baseUrl: environment.oidc.issuer.split("/oauth2")[0],
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
      issuer: environment.oidc.issuer,
      useInteractionCodeFlow: true,
      useClassicEngine: true,
      authParams: {
        issuer: environment.oidc.issuer,
        scopes: environment.oidc.scopes,
        display: "page",
        responseType: environment.oidc.responseType,
      },
      logo: "assets/Images/Drive_Werner_Logo@3x.png",
      language: "en",
      i18n: {
        en: {
          "primaryauth.title": "Sign in to Drive Werner Pro",
        },
      },
      authClient: this.config,
      features: {
        showPasswordToggleOnSignInPage: true,
      },
    });
  }

  getWidget() {
    return this.widget;
  }

  async getUserInfo() {
    if (this.userInfo) return this.userInfo;

    const userClaim = await this.oktaAuth.token.getUserInfo();
    const userInfo: IUserInfo = {
      identityKey: userClaim.identityKey,
      user_name: userClaim.user_name,
      name: userClaim.name,
      preferred_username: userClaim.preferred_username,
      given_name: userClaim.given_name,
    };
    this.userInfo = userInfo;

    const identityKeyCheck = await Preferences.get({
      key: StorageConstants.USER_IDENTITY_KEY,
    });

    // If the logged in user is different, clear storage
    if (
      identityKeyCheck.value !== null &&
      identityKeyCheck.value !== this.userInfo.identityKey
    ) {
      this.clearStorage();
    }
    this.featureFlags = await this.dispatchService.getFeatureFlags();

    this.isPendoEnabled =
      this.featureFlags?.flags?.PendoEnabled !== undefined
        ? this.featureFlags?.flags?.PendoEnabled
        : null;
    if (this.isPendoEnabled) {
      this.initializePendo();
    }

    await Preferences.set({
      key: StorageConstants.USER_IDENTITY_KEY,
      value: this.userInfo.identityKey,
    });

    return userInfo;
  }
  private initializePendo(): void {
    pendo.initialize({
      visitor: {
        id: this.userInfo.preferred_username,
        identityKey: this.userInfo.identityKey,
        givenName: this.userInfo.given_name,
        user_name: this.userInfo.user_name,
        name: this.userInfo.name,
      },
    });
  }
  public async setUserProfile() {
    await this.getUserInfo();
    this.dispatchService.homeLoadDispatch(this.userInfo.identityKey);
  }

  async extractStorageKeysNotToBeDeleted() {
    // Keys that should not be deleted even after the user logs off.
    // E.g. Customized cards
    var keysNotToBeDeleted = new Array<StorageKeyValue>();

    let keys = (await Preferences.keys()).keys;
    if (keys && keys.length > 0) {
      keys.forEach(async (key) => {
        if (
          key.startsWith(StorageConstants.CARDS_KEY + "_") ||
          key.startsWith(StorageConstants.DEEP_LINK_URL)
        ) {
          let item = await Preferences.get({ key: key });
          keysNotToBeDeleted.push({
            key: key,
            value: item.value,
          });
        }
      });
    }
    return keysNotToBeDeleted;
  }

  async SetUrlQueryParams(pathValues :string)
  {
    this.urlQueryParams = pathValues
        .replace("?", "")
        .split("&")
        .reduce(function (prev, curr, i, arr) {
          var p = curr.split("=");
          prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
          return prev;
        }, {});
  }

  async processDeepLinksRouting(url: string) {
    AuthService.isRequestFromDeeplink = true;
    AuthService.isScanPaperworkSuccess = true;
    url = url + "&requestFrom=deeplink";
    const routes = DeepLinkRoutes.routes;
    const pathArray = this.getUrlData(url);
    if (pathArray.length >= 3) {      
       this.SetUrlQueryParams(pathArray[3]);
      if (await this.validateDeepLinkAuthentication(this.urlQueryParams)) {
        const getRouteInfo = routes.find(
          (route) => route.pathName === pathArray[2]
        );

        this.tripId=0;
        if(this.urlQueryParams["tripId"]!=null)
        {
          this.tripId =  this.urlQueryParams["tripId"].toString().split('-')[0];
        }
        
        let pageName = (await this.GetPageName(this.urlQueryParams)).toString();
        if(pageName == "" || pageName == null)
        {
          await this.invalidTripDialogBox();         
        }
        if(pageName != "")
        {
          await Preferences.remove({ key: StorageConstants.DEEP_LINK_URL });
          const queryParamDetails = {
            page: pageName,
            requestFrom: "deeplink",
            tripId: this.tripId,
            employeeNumber: this.urlQueryParams.employee_number,
          };
          this.router.navigate([getRouteInfo.orginalPath], {
            queryParams: queryParamDetails,
          });
        }
      } else  {
        await Preferences.set({
          key: StorageConstants.DEEP_LINK_URL,
          value: url,
        });
        this.timeout=setTimeout(()=>this.logoutConfirmDialogBox(),5000);
        return;
      }
    } else {
      return false;
    }
  }

  getUrlData(deeplinkUrl) {
    let data = deeplinkUrl.split("://");
    var protocol = data[0];
    data = data[1].split("/");
    var domain = data[0];
    return [protocol, domain, data[1], data[2]];
  }

  async logoutConfirmDialogBox() {
    const ionAlert = await this.alertController.create({
      header: "INCORRECT USER",
      message:
        "The DWP app user does not match the device user. Please log in with the correct credentials.",
      cssClass: "custom-alert",
      backdropDismiss: false, // Disable clicking on the backdrop to dismiss the alert
      buttons: [
        {
          text: "Login with correct user",
          cssClass: "full-width-button",
          handler: async () => {
            clearTimeout(this.timeout);
            await this.signOut();
            ionAlert.onDidDismiss();
          },
        },
      ],
    });
    await ionAlert.present();    
  }

  async invalidTripDialogBox() {
    const loader = await this.loadingController.create()
    const ionAlert = await this.alertController.create({
      message:
        "This trip doesn't exist for this driver ",
      backdropDismiss: false, // Disable clicking on the backdrop to dismiss the alert
      buttons: [
        {
          text: "Close",
          handler: () => {
             loader.present()
             deeplinkPlugin.DeepLinkFailureResponse({});
          },
        }
      ],
    });
    
    await ionAlert.present();
    this.loadingUtility.hideLoader()
    await ionAlert.onDidDismiss();
  }

  async validateDeepLinkAuthentication(queryParams) {
    if ("employee_number" in queryParams) {
      const employeeNumber = await this.dispatchService.getEmployeeNumber();  
      if (
        queryParams["employee_number"].toString() ===
        employeeNumber.toString()
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async GetPageName(queryParams): Promise<string> {
    const driverId = await Preferences.get({
      key: StorageConstants.USER_IDENTITY_KEY,
    });
    var pageName = "";    

    let employeeNumber = queryParams["employee_number"].toString();    

     let currentTrip;
     await this.loadingUtility.showLoader("Please wait while we load the trip data ...");
      this.subscriptions$.sink = this.requestService.tripsCurrentGet(parseInt(employeeNumber)).subscribe(
        async data => {
          if (data) {
            currentTrip = data;
          }
        }
      ); 
      let isCurrentTrip=currentTrip?.id === this.tripId;   
      if(isCurrentTrip)
      {
        pageName= "Current";
      }
      else
      {
        let previousTrips;
        await this.loadingUtility.showLoader("Please wait while we load the trip data...");
        await this.store.dispatch(new previousTripsActions.GetPreviousTrips(driverId.value,employeeNumber));
        previousTrips = await new Promise((resolve) => {
          this.subscriptions$.sink = this.store.pipe(select(fromPreviousTrips.getDriverPreviousTrips)).subscribe(
            data => {
              if (data) {               
                resolve(data);
              }
            }
          );
        });    
        let IsPreviousTrip= previousTrips?.find(trip => trip.id === this.tripId);
        if(IsPreviousTrip)
        {
          pageName= "Previous";
        }
      }
      return pageName;
  }

  async addKeysToStorage(keyValues: Array<StorageKeyValue>) {
    // Add the keys back to storage
    if (keyValues.length > 0) {
      for (let i = 0; i < keyValues.length; i++) {
        Preferences.set({ key: keyValues[i].key, value: keyValues[i].value });
      }
    }
  }

  async clearStorage() {
    // Extract keys that are not to be deleted
    const keysNotToBeDeleted = await this.extractStorageKeysNotToBeDeleted();

    // Clear the storage
    if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
      localStorage.clear();
    } else {
      localStorage.clear();
      await Preferences.clear();
    }

    // Add keys back to the storage
    await this.addKeysToStorage(keysNotToBeDeleted);
  }

  async signOut(): Promise<void> {
    await this.clearStorage().then(async () => {
      // Sign out method was redirecting to browser, so manually clearing all the tokens and redirecting to log in page
      this.oktaAuth.tokenManager.clear();
      let refreshToken =
        this.oktaAuth.tokenManager.getTokensSync().refreshToken;
      let accessToken = this.oktaAuth.tokenManager.getTokensSync().accessToken;
      await this.oktaAuth.revokeRefreshToken(refreshToken);
      await this.oktaAuth.revokeAccessToken(accessToken);
      await this.oktaAuth.closeSession();
      window.location.replace("/login");
    });
  }

  onDevice(): boolean {
    return this.platform.is("mobile") && !this.platform.is("mobileweb");
  }

  async getUserIdentitityKey() {
    const identityKey = await Preferences.get({
      key: StorageConstants.USER_IDENTITY_KEY,
    });
    return identityKey?.value ? identityKey.value : "";
  }
}
