
<ion-tabs>
  <ion-header>
    <app-toolbar *ngIf="showHeader  && !isRequestfromDeeplink "></app-toolbar>
  </ion-header>
  <ion-tab-bar *ngIf="(!(platform.is('desktop') || platform.is('mobileweb')) && !isRequestfromDeeplink )" slot="bottom">
    <ion-tab-button [class.tab-selected]="pageUrl == '/'" (click)="didTap('/')">
      <ion-icon src="assets/Images/gauge-high-regular.svg"></ion-icon>
      <ion-label>Home</ion-label>
    </ion-tab-button>
    <ion-tab-button [class.tab-selected]="pageUrl.includes('contact') && !pageUrl.includes('account_contacts')" (click)="didTap('contact')">
      <ion-icon src="assets/Images/address-book-regular.svg"></ion-icon>
      <ion-label>Contacts</ion-label>
    </ion-tab-button>
    <ion-tab-button  [class.tab-selected]="pageUrl.includes('maps')" (click)="didTap('maps')">
      <ion-icon src="assets/Images/map-location-dot-regular.svg"></ion-icon>
      <ion-label>Map</ion-label>
    </ion-tab-button>
    <ion-tab-button [class.tab-selected]="pageUrl.includes('loads')" (click)="didTap('loads')">
      <ion-icon src="assets/Images/truck-moving-long.svg"></ion-icon>
      <ion-badge class="badge" color="danger" *ngIf="noPaperSubmittedJobsCount > 0"> {{noPaperSubmittedJobsCount}} </ion-badge>
      <ion-label>Jobs</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
