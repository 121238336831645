import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PaperworkActionTypes, PaperWorkActions } from "./paperwork.actions";
import * as fromRoot from "../../../app.state";
import { TripPakImageResponse } from 'src/app/Swagger-Gen/model/tripPakImageResponse';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';
import { ScannedPaperwork } from "src/app/Swagger-Gen-V2/model/ScannedPaperwork";

export interface State extends fromRoot.State {
  tripEnvelop: PaperWorkState;
}

export interface PaperWorkState {
  tripEnvelopImages: Array<TripPakImageResponse>;
  tripEnvelopId: string;
  paperWorkConfirmationPosted: ScannedPaperwork;
  paperWorkConfirmation: ScannedPaperwork[];
  error: string;
  selectedJob: JobTrip;
  IsPostSuccess: boolean;
}

const initialState: PaperWorkState = {
  tripEnvelopImages: null,
  tripEnvelopId: null,
  paperWorkConfirmationPosted: null,
  paperWorkConfirmation: null,
  error: "",
  selectedJob: null,
  IsPostSuccess: null
};

const getPaperWorkFeatureState = createFeatureSelector<PaperWorkState>(
  "paperWork"
);

export const getTripEnvelopId = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.tripEnvelopId : null)
);

export const getTripEnvelopImage = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.tripEnvelopImages : null)
);

export const isPostPaperworkSuccess = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.IsPostSuccess: null)
);


export const getPaperWorkConfirmationPosted = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.paperWorkConfirmationPosted : null)
);

export const getPaperWorkConfirmation = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.paperWorkConfirmation : null)
);

export const getSelectedJob = createSelector(
  getPaperWorkFeatureState,
  (state) => (state != null ? state.selectedJob : null)
);


export const getError = createSelector(
  getPaperWorkFeatureState,
  (state) => state.error
);

export function paperWorkReducer(
  state = initialState,
  action: PaperWorkActions
): PaperWorkState {
  switch (action.type) {
    case PaperworkActionTypes.PostJobEnvelop:
      return {
        ...state,
        tripEnvelopId:  null,
        error: "",
      };
    case PaperworkActionTypes.PostJobEnvelopSuccess:
      let payload = action.payload;
      return {
        ...state,
        tripEnvelopId: (payload && payload.length >= 0) ? payload[0].envelopId : null,
        tripEnvelopImages: (payload && payload.length >= 0) ? payload : null,
        error: "",
      };
    case PaperworkActionTypes.PostJobEnvelopFail:
      return {
        ...state,
        tripEnvelopId: null,
        error: action.payload,
      };
      case PaperworkActionTypes.GetJobEnvelopImage:
      return {
        ...state,
        tripEnvelopImages: null,
        error: "",
      };
    case PaperworkActionTypes.GetJobEnvelopImageSuccess:
      return {
        ...state,
        tripEnvelopImages: action.payload,
        error: "",
      };
    case PaperworkActionTypes.GetJobEnvelopImageFail:
      return {
        ...state,
        tripEnvelopImages: null,
        error: action.payload,
      };
    /////////
    case PaperworkActionTypes.PostPaperWorkConfirmationSuccess:
      return {
        ...state,
       IsPostSuccess: true,
        error: "",
      };
      case PaperworkActionTypes.PostPaperWorkConfirmation:
        return {
          ...state,
         IsPostSuccess: null,
          error: "",
        };
    case PaperworkActionTypes.PostPaperWorkConfirmationFail:
      return {
        ...state,
        IsPostSuccess: false,
        error: action.payload,
      };
      case PaperworkActionTypes.GetPaperWorkConfirmation:
        return {
          ...state,
          paperWorkConfirmation: null,
          error: "",
        };
    case PaperworkActionTypes.GetPaperWorkConfirmationSuccess:
      return {
        ...state,
        paperWorkConfirmation: action.payload,
        error: "",
      };
    case PaperworkActionTypes.GetPaperWorkConfirmationFail:
      return {
        ...state,
        paperWorkConfirmation: null,
        error: action.payload,
      };

      case PaperworkActionTypes.ConfirmationCodesToBePosted:
      return {
        ...state,
        paperWorkConfirmationPosted: action.confimationCode,
        error: "",
      };

      case PaperworkActionTypes.SelectedJob:
      return {
        ...state,
        selectedJob: action.trip,
        error: "",
      };

    default:
      return state;
  }
}
