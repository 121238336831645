import { Injectable, Injector } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';
@Injectable()
export class PdfViewerUtility {
    loadingUtility: LoadingUtility;
    toastController: ToastController;
    downloadedFile: Blob;
    constructor(
        private injector: Injector,
        private file: File,
        private http: HTTP,
        public platform: Platform,
        private fileOpener: FileOpener,
        private inAppBrowser: InAppBrowser,
    ) {
        this.loadingUtility = injector.get<LoadingUtility>(LoadingUtility);
    }

    downloadAndViewPDF(url,target) {
        if ((this.platform.is("desktop") || this.platform.is("mobileweb")) || this.platform.is("ios")) {
            this.inAppBrowser.create(url, target, {
                location: 'no', // hide the location bar
                hidenavigationbuttons: 'yes' // hide the navigation buttons
            });
        } else {
            this.loadingUtility.showLoader("Please wait...");
            this.http.sendRequest(url, { method: "get", responseType: "arraybuffer" }).then(
                async (httpResponse) => {
                    this.downloadedFile = await new Blob([httpResponse.data], { type: 'application/pdf' });
                    await this.onViewPdf();
                }
            ).catch(err => {
                this.showError();
            })
        }
    }

    async onViewPdf() {
        const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
        this.file.writeFile(writeDirectory, 'Pay Details', this.downloadedFile, { replace: true })
            .then(() => {
                this.loadingUtility.hideLoader();
                this.fileOpener.open(writeDirectory + 'Pay Details', 'application/pdf')
                    .catch(() => {
                        this.showError();
                    });
            })
            .catch(() => {
                this.showError();
            });
    }

    async showError() {
        this.loadingUtility.hideLoader();
        const toast = await this.toastController.create({
            message: 'An error occured while showing PDF. Please try again.',
            duration: 3000
        });
        toast.present();
    }

}
