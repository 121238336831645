import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { StorageConstants } from 'src/app/shared/utilities/storage-constants';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';
import * as fromPreviousTrips from 'src/app/modules/loads/previous-trips-loads/state/previous-trips-loads.reducer';
import * as previousTripsActions from "src/app/modules/loads/previous-trips-loads/state/previous-trips-loads.actions";
import { select, Store } from '@ngrx/store';
import { Preferences } from '@capacitor/preferences';
import { MessagesResponse } from 'src/app/Swagger-Gen-V2/model/messagesResponse';
import { ProfileInfoService } from '../profile-v2/service/profileInfo.service';
import { BottomDrawerComponent } from 'src/app/shared/components/bottom-drawer/bottom-drawer.component';
import { LoadService } from '../loads/services/load.service';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnDestroy {
  @ViewChild(BottomDrawerComponent, { static: true } ) bottomDrawer: BottomDrawerComponent; 
  componentActive = true;
  home: any;
  contact: any;
  maps: any;
  loads: any;
  pageUrl:String = "";
  showHeader:Boolean = true;
  noPaperSubmittedJobsCount = 0;
  benefitsAnnouncementsUnreadCount = 0;
  identityKey : string = "";
  subscriptions$ = new SubSink();
  isProduction : boolean = environment.production;
  benefitsAnnouncementsMessages : MessagesResponse;
  routingTypeForBenefits : number = 2;
  isAnnouncement : boolean = true;
  employeeNumber : string;
  isRequestfromDeeplink: boolean;

  constructor(private router: Router, 
    private readonly menuController: MenuController, public platform: Platform,private readonly store: Store<fromPreviousTrips.State>, private readonly profileInfoService : ProfileInfoService, private readonly loadService: LoadService, private readonly dispatchService: DispatchService, private readonly authService: AuthService) {     
      // detect router navigation and show header based on device platform
      this.subscriptions$.sink = router.events.subscribe((val) => {
        if(val instanceof NavigationEnd){
          this.pageUrl  = val.urlAfterRedirects;
          this.isRequestfromDeeplink = AuthService.isRequestFromDeeplink
          this.headerRule();
        }
      });
    }
    
  
  async ionViewWillEnter ()  {
    await this.getIdentityKey();
    await this.getJobsCount();
    await this.getBenefitsCount();
  }
  
  private headerRule() {
    const isDevice = this.platform.is("desktop") || this.platform.is("tablet") || this.platform.is("mobileweb");
    this.showHeader = isDevice || this.pageUrl === "/";
  }
  
  async didTap(tab: any) {
    await this.loadService.closeBottomDrawer.next(true);
    if (tab === 'contact') {
      localStorage.setItem('contact-selected-tab', '0');
    }
    setTimeout(() => {
      this.router.navigate([tab]);
    },100);
    
    await this.menuController.close('sideMenu');
  }

  async getCount() {
    await this.getIdentityKey();
    this.getJobsCount ();
    this.getBenefitsCount();
  }

  async getIdentityKey() {
    const identityKeyValue = await Preferences.get({
      key: StorageConstants.USER_IDENTITY_KEY,
    });
    this.identityKey = identityKeyValue.value;
  }

  async getJobsCount() {      
    if (this.identityKey) {
      // Fetch prvious job data from state
      this.subscriptions$.sink = this.store.pipe(select(fromPreviousTrips.getDriverPreviousTrips)).subscribe(
      previousTrips => { 
        if (previousTrips ) { 
          this.noPaperSubmittedJobsCount = previousTrips.filter(function(obj){ return obj.paymentStatus === "" }).length;
        }else{
          // Fetch prvious job data from api , if not exist in state
          this.profileInfoService.getUserProfileInfo().then(async (profile) => {
            this.employeeNumber = await this.dispatchService.getEmployeeNumber();
            this.store.dispatch(new previousTripsActions.GetPreviousTrips(this.identityKey,this.employeeNumber));           
          });             
        }
      })      
    }
  }

  async getBenefitsCount() {
    this.dispatchService.benefitsCount.subscribe((count) => {
      this.benefitsAnnouncementsUnreadCount = count;
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    this.componentActive = false;
  }
}
