export class DeepLinkRoutes  {
    static routes = [
     {
         "pathName": "paperwork",
         "orginalPath": "deep-link-landing",
         "pathVal1": "stakeHolderId",
         "pathVal2": "tripId",
         "pathVal3" : "requestFrom"
     }
    ];
 }