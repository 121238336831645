import { Injectable } from '@angular/core';
import { LoadingController} from '@ionic/angular';
import { LoadingProperties } from 'src/app/shared/models/LoadingProperties';

@Injectable()
export class LoadingUtility {
    loader: any = null;    
    isLoading: boolean = false;
    constructor(private loadingController: LoadingController) {
    }

    private async showLoadingHandler(message: string, parameters: LoadingProperties) {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        let loadingProperties = new LoadingProperties();
        if(message)
        {
            loadingProperties.message = message;
        } else {
            loadingProperties = parameters;
        }

        if (this.loader == null) {
            this.loader = await this.loadingController.create(loadingProperties);
            this.loader.present();
        } else {
            this.loader.data.content = message;
        }
    }

    private async hideLoadingHandler() {
        if (this.loader != null) {
            this.loader.dismiss();
            this.loader = null;
        }
    }

    public async showLoader(message: string) {
        await this.showLoadingHandler(message, null);
    }

    public async showLoaderWithProperties(parameters: LoadingProperties) {
        await this.showLoadingHandler(null,  parameters);
    }

    public async hideLoader() {
        this.isLoading = false;
       await this.hideLoadingHandler();
    }
}