export class LaunchDarklyConstants {
    static DWP_GAMIFICATION_ENABLED  = 'dwp-gamification-enabled';
    static DWP_TAT_ENABLED  = 'dwp-tat-enabled';
    static DWP_CASSCHAT_ENABLED = 'dwp-cass-chatbot-enabled';
    static DWP_MILITARY_STATUS_ENABLED = 'dwp-military-status-enabled';
    static DWP_DISABILITY_STATUS_ENABLED = 'dwp-disability-status-enabled';
    static DWP_MARITAL_STATUS_ENABLED = 'dwp-marital-status-enabled';
    static DWP_ETHNICITY_ENABLED = 'dwp-ethnicity-enabled';
    static DWP_HISPANIC_OR_LATINO_STATUS_ENABLED = 'dwp-hispanic-or-latino-status-enabled';
    static DWP_GENDER_ENABLED = 'dwp-gender-enabled';
    static DWP_PRONOUN_ENABLED = 'dwp-pronoun-enabled';
}

export class LaunchDarklyRoutes {
    static ROUTES = [
        {
            'key': 'dwp-cass-chatbot-enabled',
            'url': 'cass-chat',
            'menuLabel': 'Cass Chat',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-tat-enabled',
            'url': 'truckers-against-trafficking',
            'menuLabel': 'Truckers Against Trafficking',
            'isMenuAvailable':true,
            'homeCardTitle':'TruckersAgainstTrafficking',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-gamification-enabled',
            'url': 'blue-coins',
            'menuLabel': 'Blue Coin',
            'isMenuAvailable':true,
            'homeCardTitle':'BlueCoin',
            'isHomeCardAvailable':true,
        },
        {
            'key': 'dwp-military-status-enabled',
            'url': '',
            'menuLabel': 'Military Status Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-marital-status-enabled',
            'url': '',
            'menuLabel': 'Marital Status Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-disability-status-enabled',
            'url': '',
            'menuLabel': 'Disability Status Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-ethnicity-enabled',
            'url': '',
            'menuLabel': 'Ethnicity Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-hispanic-or-latino-status-enabled',
            'url': '',
            'menuLabel': 'Hispanic or latino field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },
        {
            'key': 'dwp-gender-enabled',
            'url': '',
            'menuLabel': 'Gender Status Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        }, 
        {
            'key': 'dwp-pronoun-enabled',
            'url': '',
            'menuLabel': 'Pronoun Status Field',
            'isMenuAvailable':false,
            'homeCardTitle':'',
            'isHomeCardAvailable':false,
        },                                                                
    ];
}